import { AssetStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { ImageJsonLd } from 'next-seo';
import styles from './ImageService.module.scss';

import React from 'react';
import clsx from 'clsx';

type SBImageProps = {
    image: AssetStoryblok;
    height?: number;
    width?: number;
    fit?: 'cover' | 'contain';
    params?: string;
    className?: string;
};
/**
 * Image component that handles retina display images
 * @param image Image object
 * @param width Optiponal width in pixels
 * @param height Optional height in pixels
 * @param fit Optional parameter for object-fit style property
 * @param params Optional image service parameters
 */
export const SBImage: React.FC<SBImageProps> = ({ image, height = 0, width = 0, fit, params = '', className }) => {
    // prettier-ignore
    // Set mutliple source sets for different device pixel ratios (dpr)
    const srcSet = `${image.filename}/m/${width}x${height}${params}, 
                    ${image.filename}/m/${width * 2}x${height * 2}${params} 2x, 
                    ${image.filename}/m/${width * 3}x${height * 3}${params} 3x`;

    return (
        <>
            <ImageJsonLd
                images={[
                    {
                        contentUrl: image.filename,
                        creator: {
                            '@type': 'Person',
                            name: image.name,
                        },
                        creditText: image.copyright,
                        copyrightNotice: '©' + image.copyright,
                    },
                ]}
            />
            <picture>
                <img
                    className={clsx(styles.image, className)}
                    style={{ objectFit: fit }}
                    src={`${image.filename}/m/${width}x${height}${params}`}
                    alt={image.alt}
                    srcSet={srcSet}
                    height={height ? height : ''}
                />
            </picture>
        </>
    );
};
