import { LinkData } from '@/components/ArticleLayout/ArticleDesktopNavbar/ArticleDesktopNavbar';
import { MenuLinkStoryblok } from '../types/storyblok/storyblok-types';

export function navigation() {
    return {
        '--swiper-navigation-size': '44px',
        '--swiper-navigation-color': 'var(--core95)',
    };
}

export const links = (content: any[], Navbar?: MenuLinkStoryblok[]) => {
    if (content === undefined) return [];
    let anchorLinks: LinkData[] = [];
    let totalLinks = 0;

    if (Navbar) {
        for (let i = 0; i < Navbar.length; i++) {
            ++totalLinks;
            anchorLinks.push({
                anchor: Navbar[i].name!,
                _uid: Navbar[i]._uid,
                title: Navbar[i].name,
                count: totalLinks,
            });
        }
    }
    return anchorLinks;
};

export const animateScroll = (startLocation: number, targetLocation: number, duration: number) => {
    const distance = targetLocation - startLocation;
    const startTime = performance.now();

    const easeInOutCubic = (t: number) => {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    };

    const step = (currentTime: number) => {
        const timeElapsed = currentTime - startTime;
        const progress = easeInOutCubic(timeElapsed / duration);

        window.scrollTo(0, progress * distance + startLocation);

        if (timeElapsed < duration) {
            window.requestAnimationFrame(step);
        }
    };

    window.requestAnimationFrame(step);
};
