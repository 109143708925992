import { ReactElement, useEffect, useState } from 'react';
import { getMindentityDataURL } from '@/shared/lib/Mindentity.script.mjs';

import clsx from 'clsx';
import styles from './Mindentity.module.scss';

type MindentityProps = {
    name: string;
    color?: string;
    isDark?: boolean;
    className?: string;
    children: ReactElement;
    hover?: boolean;
    isGrey?: boolean;
    isServiceCard?: boolean;
};

export const Mindentity: React.FC<MindentityProps> = ({
    name,
    color,
    isDark,
    className,
    children,
    isGrey,
    hover = true,
    isServiceCard,
}) => {
    const [start, setStart] = useState(false);
    const [whitespace, setWhitespace] = useState(100);
    const backgroundColor = isGrey
            ? isServiceCard
                ? '#E1E3E5'
                : '#1A1716'
            : isDark
            ? isServiceCard
                ? '#1A1716'
                : '#0D0C0B'
            : '#EDEFF2',
        defaultColor = isDark ? '#FFF' : '#0D0C0B';

    const options = {
        margin: 10,
        mode: 'string',
        input: name,
        foregroundColor: color || defaultColor,
        backgroundColor: backgroundColor,
        whiteSpace: !hover ? 20 : whitespace,
    };

    const onStart = () => {
        setStart(true);
        animateValue(100, 20, 500);
    };

    const onStop = () => {
        animateValue(20, 100, 300);
        setTimeout(() => setStart(false), 350);
    };

    function animateValue(start: number, end: number, duration: number) {
        let startTimestamp: any = null;
        const step = (timestamp: any) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            setWhitespace(Math.floor(progress * (end - start) + start));
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }

    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    return (
        <div>
            {isClient &&
                (hover ? (
                    <div className={clsx(styles.wrapper, className)} onMouseEnter={onStart} onMouseLeave={onStop}>
                        {children}
                        {start && (
                            <picture>
                                <img
                                    src={getMindentityDataURL(options as any)}
                                    alt="Mindentity"
                                    className={styles.mask}
                                />
                            </picture>
                        )}
                    </div>
                ) : (
                    <div className={clsx(styles.wrapper, className)}>
                        {children}
                        <picture>
                            <img
                                src={getMindentityDataURL(options as any)}
                                alt="Mindentity"
                                className={clsx(styles.mask, isServiceCard && styles.crop)}
                            />
                        </picture>
                    </div>
                ))}
        </div>
    );
};
