/**
 * @param mainColor color in the form of 'var(--color)'
 * @param dark if dark, append Dark ; else append Light
 * @returns the correct version of @param mainColor based on the section theme
 */
export function getColor(mainColor: string, dark: boolean, pageType?: string, returnHex?: boolean): string {
    if (mainColor === '#d94716') {
        //handle default
        if (pageType === 'projects') {
            return returnHex ? '#14CC9F' : 'var(--turqoiseDark)';
        } else if (pageType === 'job') {
            return returnHex ? '#E52EB8' : 'var(--pinkDark)';
        } else if (pageType === 'service') {
            return returnHex ? '#F2B600' : 'var(--yellowDark)';
        }
        return returnHex ? '#E54B17' : 'var(--orangeDark)';
    }
    mainColor = mainColor.substring(0, mainColor.length - 1);
    if (dark) {
        return mainColor.concat('Dark)');
    }
    return mainColor.concat('Light)');
}
/**
 * @param hexColor color you want to use to emphasize text
 * @param title the full string, using **bold** to decide what text to emphasize
 * @returns html
 */
export function colorText(mainColor: string, title: string, darkTheme: boolean): string {
    const color = getColor(mainColor, darkTheme);
    const tokens = title.split('**');
    let counter = 1;
    let stringBuilder = '';
    for (let tok of tokens) {
        if (tok === '') {
            counter++;
            continue;
        } else if (counter % 2 === 0) {
            stringBuilder += '<span style="color:' + color + '">' + tok + '</span>';
        } else {
            stringBuilder += '<span>' + tok + '</span>';
        }
        counter++;
    }
    return stringBuilder;
}
