import { SbBlokData, storyblokEditable } from '@storyblok/react';
import clsx from 'clsx';
import { ReactNode, forwardRef } from 'react';
import styles from './Container.module.scss';

type CommonProps = {
    children: ReactNode;
    code: 'standard' | 'large' | 'fullpage' | 'custom';
    alignment?: 'left' | 'center' | 'right';
};

type WrapperProps = CommonProps & {
    className?: string;
    style?: any;
};

type ContainerProps = CommonProps & {
    blok?: SbBlokData;
    className?: {
        root?: string;
        wrap?: string;
    };
    style?: {
        root?: any;
        wrap?: any;
    };
};

const getWrapperClassNames = (code: string, alignment: string | undefined, className?: string) => {
    return clsx(className, styles.wrapper, {
        [styles.fullpage]: code === 'fullpage',
        [styles.large]: code === 'large',
        [styles.standard]: code === 'standard',
        [styles.center]: alignment === 'center',
        [styles.left]: alignment === 'left',
        [styles.right]: alignment === 'right',
    });
};

const Wrapper = forwardRef<HTMLDivElement, WrapperProps>(({ children, style, className, code, alignment }, ref) => {
    return (
        <div
            ref={ref}
            style={style?.wrap}
            className={clsx(getWrapperClassNames(code, alignment, className), styles.wrapper)}
        >
            {children}
        </div>
    );
});

const Container = forwardRef<HTMLDivElement, ContainerProps>(
    ({ children, code, className, alignment = 'center', style, blok }, ref) => {
        const content = (
            <Wrapper ref={ref} code={code} alignment={alignment} className={className?.wrap} style={style}>
                {children}
            </Wrapper>
        );
        return (
            <>
                {blok ? (
                    <div
                        {...storyblokEditable(blok)}
                        id={blok?.anchor as string}
                        className={clsx(styles.contRoot, className?.root)}
                        style={style?.root}
                    >
                        {content}
                    </div>
                ) : (
                    content
                )}
            </>
        );
    },
);
Wrapper.displayName = 'Wrapper';
Container.displayName = 'Container';

export default Container;
