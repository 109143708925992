import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './typography.module.scss';

type TypoProps = {
    darkTheme?: boolean;
    children?: string | number | ReactNode;
    href?: string;
    className?: string;
};

type LinkProps = TypoProps & {
    download?: string;
};

const H1: React.FC<TypoProps> = ({ darkTheme, children, className }) => (
    <h1 className={clsx(darkTheme ? styles.h1_dark : styles.h1, className)}>{children}</h1>
);
const H2: React.FC<TypoProps> = ({ darkTheme, children, className }) => (
    <h2 className={clsx(darkTheme ? styles.h2_dark : styles.h2, className)}>{children}</h2>
);
const H3: React.FC<TypoProps> = ({ darkTheme, children, className }) => (
    <h3 className={clsx(darkTheme ? styles.h3_dark : styles.h3, className)}>{children}</h3>
);
const H4: React.FC<TypoProps> = ({ darkTheme, children, className }) => (
    <h4 className={clsx(darkTheme ? styles.h4_dark : styles.h4, className)}>{children}</h4>
);
const P: React.FC<TypoProps> = ({ darkTheme, children, className }) => (
    <div className={clsx(darkTheme ? styles.p_dark : styles.p, className)}>{children}</div>
);
const Compressed: React.FC<TypoProps> = ({ darkTheme, children, className }) => (
    <div className={clsx(darkTheme ? styles.p_compressed_dark : styles.p_compressed, className)}>{children}</div>
);
const Link: React.FC<LinkProps> = ({ darkTheme, children, href, className, download }) => (
    <a className={clsx(darkTheme ? styles.p_link_dark : styles.p_link, className)} href={href} download={download}>
        {children}
    </a>
);
const Small: React.FC<TypoProps> = ({ darkTheme, children, className }) => (
    <small className={clsx(darkTheme ? styles.small_dark : styles.small, className)}>{children}</small>
);
const Quote: React.FC<TypoProps> = ({ darkTheme, children, className }) => (
    <blockquote className={clsx(darkTheme ? styles.quote_dark : styles.quote, className)}>{children}</blockquote>
);
const Article: React.FC<TypoProps> = ({ darkTheme, children, className }) => (
    <div className={clsx(darkTheme ? styles.article_filter_dark : styles.article_filter, className)}>{children}</div>
);
const ArticleQuote: React.FC<TypoProps> = ({ darkTheme, children, className }) => (
    <div className={clsx(darkTheme ? styles.article_quote_dark : styles.article_quote, className)}>{children}</div>
);
export const Typography = {
    H1,
    H2,
    H3,
    H4,
    P,
    Compressed,
    Link,
    Small,
    Quote,
    Article,
    ArticleQuote,
};
