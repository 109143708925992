import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export const useBreakpoints = () => {
    const isXXSmall = useMediaQuery({ query: '(max-width: 360px)' });
    const isExtraSmall = useMediaQuery({ query: '(min-width: 361px) and (max-width: 575px)' });
    const isSmall = useMediaQuery({ query: '(min-width: 576px) and (max-width: 767px)' });
    const isMedium = useMediaQuery({ query: '(min-width: 768px) and (max-width: 991px)' });
    const isLarge = useMediaQuery({ query: '(min-width: 992px) and (max-width: 1299px)' });
    const isExtraLarge = useMediaQuery({ query: '(min-width: 1299px)' });
    const [breakPoints, setBreakPoints] = useState<any>({ isLarge: true });

    useEffect(() => {
        setBreakPoints({ isXXSmall, isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge });
    }, [isXXSmall, isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge]);

    return breakPoints;
};
